// PARCELS SKIN

// @font-face {
//   font-family: '12kfont-reg';
//   src: url('./assets/fonts/Kiona-Regular.eot'); /* IE9 Compat Modes */
//   src: url('./assets/fonts/Kiona-Regular.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */ url('./assets/fonts/Kiona-Regular.otf') format('opentype'),
//     /* Open Type Font */ url('./assets/fonts/Kiona-Regular.svg') format('svg'),
//     /* Legacy iOS */ url('./assets/fonts/Kiona-Regular.ttf') format('truetype'),
//     /* Safari, Android, iOS */ url('./assets/fonts/Kiona-Regular.woff') format('woff'),
//     /* Modern Browsers */ url('./assets/fonts/Kiona-Regular.woff2') format('woff2'); /* Modern Browsers */
//   font-weight: normal;
//   font-style: normal;
// }

@import url('https://use.typekit.net/frt5hnc.css');

@import url('https://fonts.googleapis.com/css?family=Lato:400,900&display=swap');

$font-family-heading: 'big-caslon-fb', 'Times New Roman', Times, serif;
$font-family-copy: 'big-caslon-fb', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

.zwoelfk-font {
  font-family: $font-family-heading;
}

// html {
//   font-size: 16px;
// }

body {
  font-family: $font-family-copy;
  color: $basetext;
  background-color: $bgcolor;
}

#root {
  padding-top: 0;
}

// NAV
#header {
  @extend .sticky-top;
  transition: transform 0.15s ease-out;
  border-bottom: 0px solid rgba($basetext, 0.08);
  z-index: 2;
  background: $bgcolor;
  //background: linear-gradient($black, rgba($black, 0.5), rgba($black, 0));

  .navbar {
    //@extend .container;

    // max-width: 1000px;

    padding-top: 20px !important;
    padding-bottom: 20px !important;
    @include media-breakpoint-down(sm) {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }

  .bg-light {
    background-color: $bgcolor !important;
  }
}
body[data-scrolled] {
  #header {
    transform: translateY(-100%);
  }
}
body[data-scrollingup],
body[data-menuopen][data-scrolled] {
  #header {
    transform: translateY(0%);
  }
}

#header .navbar {
  .navbar-brand {
    color: $white;
    // text-transform: uppercase;
    font-weight: 600;
    img {
      //height: auto;
      //max-height: 1.8rem;

      &:hover,
      &:focus {
        // filter: opacity(0.66);
      }
    }
    @include media-breakpoint-down(sm) {
      img {
        //height: auto;
        // max-height: 1rem;
      }
      // margin: 0;
      // height: 3.5rem;
      // max-height: 3.5rem;
    }
  }
}

#header {
  // big screens
  // padding-top: 20px;
  .main-menu {
    // hide the main menu hamburger
    display: none !important;
    flex-grow: 0;
  }

  .navbar .secondary-menu {
    justify-content: end !important;
    padding-left: 1.5rem;
    .nav-item.last a {
      padding-right: 0;
    }

    .navbar-nav {
      color: $white;
      // text-transform: uppercase;
    }
  }

  // small screens
  @include media-breakpoint-down(sm) {
    .navbar-brand-container {
      flex-grow: 1;
    }

    .main-menu {
      display: flex !important;
    }

    .navbar .secondary-menu {
      //justify-content: space-between !important;
      padding-left: 0;

      .menu {
        // hide category links
        display: none;
      }
    }
    .flex-nowrap {
      justify-content: space-between;
    }
  }
}

// body[data-scrolled] {
//   #header:has(#cart-menu.open),
//   #header:has(#main-menu.open) {
//     opacity: 1 !important;
//     transform: translateY(0px);
//   }
// }

.navbar {
  .hamburger {
    margin-right: 0.5rem;
    @include media-breakpoint-down(sm) {
      padding-left: 0.5rem;
    }
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    height: 1px;
    color: $white;
    background-color: $basetext !important;
  }

  .hamburger:hover {
    transform: scale(1.1) !important;
    transition: transform 0.15s ease, opacity 0.15s ease;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
  //margin-left: 20px;
  //min-width: 70px !important;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  margin-right: 0;
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
      margin-right: 0 !important;
    }
  }
}

.navbar .hamburger.is-active .hamburger-inner:after {
  top: 0 !important;
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
  //margin-left: 20px;
  //min-width: 70px !important;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

#header .navbar .navbar-collapse .navbar-nav,
#header .navbar .secondary-menu .navbar-nav {
  .nav-item a {
    color: rgba($basetext, 1);
    font-size: 18px;
    font-family: $headings-font-family;
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    @include media-breakpoint-down(md) {
      padding: 0.25rem 0.4rem;
    }
    line-height: 20px;
    // text-transform: uppercase;
    &:hover,
    &.active {
      //text-decoration: underline;
      //text-underline-offset: 0.3rem;
      color: $parcelsred !important;
    }
    i {
      font-size: 1rem;
      font-weight: 600;
      //padding-bottom: 0.7rem;
    }
  }
}

.offcanvas-collapse {
  background-color: rgba($secondary, 0.95);
  top: 104px;
  box-shadow: none;
  @include media-breakpoint-down(sm) {
    top: 78px;
  }
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }
  .nav-item:hover {
    background-color: transparent;
  }
}

.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// MAIN MENU
#main-menu {
  min-width: 90vw;
  height: 100vh;
  padding-top: 3rem;
  border-right: 0.1rem solid rgba($basetext, 0.08);
}

// OC CART
.offcanvas-collapse-right {
  width: min(90vw, 480px);
  height: 100vh !important;
  padding-top: 1rem;
  margin-left: 2rem;
  // box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  // border-left: 0.1rem solid rgba($basetext, 0.08);
  h5 {
    font-size: $h5-font-size;
    // text-transform: uppercase;
    color: $basetext !important;
  }
}

.product-listing-page {
  &:not([data-scrolled]) #header .main-navigation.bg-dark {
    background: fade-out($dark, 0.3) !important;
    backdrop-filter: blur(10px) saturate(120%);
    box-shadow: $box-shadow-lg;
  }
  .below-main-navigation {
    //position: absolute;
    //top: $calculated-navbar-height-sm;
    z-index: 1;
    width: 100%;
    @include media-breakpoint-up(md) {
      //top: $calculated-navbar-height;
    }
  }
  #root {
    padding-top: 0;
  }
}

.zwoelfk-mobile-logo {
  max-width: 150px;
}

.product-detail {
  .product-header {
    //margin-top: -$calculated-navbar-height;
    padding-top: 0;
    margin-top: 0;
    position: sticky;
    top: $calculated-navbar-height-sm * 1.75;
    @include media-breakpoint-up(md) {
      top: $calculated-navbar-height * 1.75;
    }
    max-height: 100vh;

    justify-content: stretch;
    align-items: stretch;
  }

  .product-content {
    position: relative;
    z-index: 1;
    background-color: $body-bg;
  }

  .variant-chooser {
    margin-bottom: $form-group-margin-bottom * 2;
  }
}

.native-slider {
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  margin-bottom: 0;

  .carousel.carousel-scoll-snap {
    &,
    & .carousel-inner,
    & .carousel-item {
      width: 100%;
      display: flex;
      @supports (align-items: stretch) {
        align-items: stretch;
      }
    }
    & .carousel-item-content {
      width: 100%;
      align-items: top;
      .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: auto;
      }
    }
  }
}

h1,
h2,
.btn:not(.btn-link),
legend,
.modal-title,
#footer h5,
.loading-spinner-content {
  font-family: $font-family-heading;
  // text-transform: uppercase;
}

// NAV

// .navbar-light {
//   background-color: rgba($color: #fff, $alpha: 0.9) !important;
//   backdrop-filter: blur(10px) saturate(120%);
//   background: linear-gradient(-180deg, rgba($white, 0.9) 0%, rgba($white, 0) 100%);
// }

// TODO better solution?! dont find anyting in variables
// .navbar-light.bg-light {
//   background-color: transparent !important;
// }

#header .navbar .navbar-brand {
  padding: 0;
  img {
    height: 35.75px;
    max-height: 35.75px;
    margin-left: 20px;
    // filter: invert(1);
    // margin-top: 0;
    // margin-bottom: 0.35rem;
    // display: none;
    @include media-breakpoint-down(md) {
      display: block;
      max-height: 30px;
      // max-height: 2rem;
      margin-right: 0;
      // margin-top: 0.75rem;
      // margin-bottom: 0.75rem;
    }
  }
  &:hover {
    filter: opacity(0.66);
  }
}

.navbar-dark.bg-light {
  background-color: $white !important;
}

.navbar-dark .offcanvas-collapse {
  background: transparent;
}

.offcanvas-collapse {
  top: $calculated-navbar-height-sm + 1rem;
  @include media-breakpoint-up(md) {
    top: $calculated-navbar-height + 1rem;
  }
  //background-color: $gray-900;
  //box-shadow: inset 0 20px 15px -20px rgba(0,0,0,.15);
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: $parcelsblue !important;
    a {
      color: #fff !important;
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    font-family: $font-family-heading;
    font-size: $h5-font-size;
    //text-transform: uppercase;
    //box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// MAKI

.maki {
  .card-title {
    // font-size: 0.9rem;
    font-family: $font-family-copy;
    font-weight: 600;
    .category {
      font-family: $font-family-copy;
    }
    span.sub-title {
      //font-size: 0.8em;
    }
  }
  .maki-img-container {
    overflow: hidden;
  }
  .maki-img {
    backface-visibility: hidden;
  }
  .maki-img-hover {
    transition: opacity 0.3s;
  }
  .price {
    // font-size: 0.9rem;
  }
  &:hover,
  &:focus {
    .maki-img {
      opacity: 1;
    }
  }
}

.masonry .sushi-container {
  //outline: 1px solid $black !important;
  border-collapse: collapse;

  margin-bottom: 0;
  position: relative;

  min-width: 50%;

  @include media-breakpoint-up(md) {
    min-width: 33.33333%;
  }

  .badge-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    .badge {
      font-size: 75%;
      font-weight: bold;
    }
  }

  .card-img-overlay {
    //border-top: 1px solid $black;
    position: relative;
    display: flex;
    align-items: flex-end;
  }
}

// PDP

.product-images {
  @include media-breakpoint-up(md) {
    // border-right: 1px solid $black;
  }
  img {
    // border-bottom: 1px solid $black;
  }
  img:last-child {
    border-bottom: 0;
  }
}

.product-info-wrapper {
  @include media-breakpoint-down(sm) {
    border-top: 1px solid $black;
    z-index: 20;
  }
}

.product-info.sticky-top {
  top: $calculated-navbar-height * 3;
  margin-bottom: 4rem;
}

.detail-page {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  h1 {
    margin-top: 0;
  }
  .variant-chooser-price {
    text-align: left;
    font-weight: normal;
    font-family: $font-family-heading;
    @extend .mb-5;
  }
  .product-title {
    //text-align: left !important;
  }
  .product-description {
    text-align: left;
    p {
      text-align: left !important;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.carousel-indicators {
  display: none;
  position: relative;
  > a,
  > .btn {
    img {
      margin: 0.3rem 0.3rem !important;
      transform: scale(1) !important;
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}

.radio-list-group .product-thumb {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    padding-top: 1rem !important;
    h1,
    form {
      text-align: left;
    }
  }
}

.ticket-detail-page {
  .blurry-background-image {
    //height: 50vh;
    background-color: transparent;
  }

  .blurry-background-image:before {
    display: none;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    //display: none !important;
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    //line-height: 1.4;

    .super-title {
      font-size: 0.7rem;
      opacity: 1;
    }
    .sub-title {
      font-weight: 500;
      margin-top: 0.5rem;
      color: $black;
    }
  }

  .product-info-bar {
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    //border-bottom: 0.1rem solid rgba($basetext, 0.08);
    box-shadow: none;
    i.far {
      color: $secondary !important;
    }
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }

  .detail-page {
    padding-top: 0;
  }

  .variant-listing {
    .visible,
    .invisible {
      display: none !important;
    }
  }
}

// FOOTER

#footer {
  background-color: $white;
  // border-top: 1px solid $black;
  color: $primary;
  border-bottom: 1px solid $black;
  min-height: 0;
  img {
    //filter: invert(1);
    svg {
      fill: $parcelsblue;
    }
  }
}

.list-page {
  position: relative;
  padding-top: 10vh;
  background: $bgcolor !important;
  // box-shadow: $box-shadow-inverse-lg;
  .superhead {
    font-size: 38px;
    @include media-breakpoint-down(md) {
      padding-left: 20px;
    }
  }
}

.product-listing {
  min-height: 25vh;
  background-color: $bgcolor;
}

.video-container {
  .video-controls {
    opacity: 0.5;

    .btn-outline-light {
      border: none;
    }
  }
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

// .modal-backdrop.show {
//   backdrop-filter: none !important;
// }

// .offcanvas-collapse,
// .offcanvas-collapse-right,
// .radio-list-group,
// .navbar-nav .nav-item {
//   box-shadow: none !important;
// }

// CMS STYLES

.upline {
  font-family: $font-family-heading;
  font-weight: normal;
}

.g8-page  {
  // background-color: $primary;
  // color: white;
}

$cta-btn-height-sm: 3rem; //$calculated-navbar-height-sm;
$cta-btn-height: 5.5rem; //$calculated-navbar-height;

.landing-page-slider {
  height: 100vh;
  // height: calc(100vh - #{$calculated-navbar-height-sm});
  // @include media-breakpoint-up(md) {
  //   height: calc(100vh - #{$calculated-navbar-height});
  // }
  // height: 85vh;
  // height: calc(100vh - #{$cta-btn-height-sm * 2});
  // @include media-breakpoint-up(md) {
  //   height: 80vh;
  //   height: calc(100vh - #{$cta-btn-height * 2});
  // }
}
.landing-page-ctas {
  > [class*='col-'] {
    display: flex;
    align-items: stretch;
  }
  .btn {
    height: $cta-btn-height-sm;
    @include media-breakpoint-up(md) {
      height: $cta-btn-height;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    transition: font-size 0.05s;

    @include media-breakpoint-up(md) {
      &:hover,
      &:focus {
        font-size: 2em;
      }
    }
  }
}

.hovering-text-container {
  position: relative;
  font-family: $font-family-heading;

  .hovering-text {
    color: $white;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: $h5-font-size;
    @include media-breakpoint-up(md) {
      font-size: 3em;
    }
    @include media-breakpoint-up(lg) {
      font-size: 4em;
    }

    text-align: center;
    text-shadow: 0px 1px 7px rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

// Bootstrap introduced min-width: 0; which bugs our layout
.col {
  min-width: unset;
}

.detail-page .alert-info {
  text-align: center;
}

#cookiescript_badge {
  z-index: 999 !important;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// REMOVE SHADOW FROM TT-LISTING

table.tt-listing {
  box-shadow: none;
}
